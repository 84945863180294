require('./app_fo'); // on reprend la base du front

import 'animate.css'

// import 'owl.carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'owl.carousel';


$(document).ready(function() {

    //OWL CAROUSEL
	if ($("#owl-actus").length>0){
        $("#owl-actus").owlCarousel({
            // call here
            nav : false,
            dots : true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            items : 1,
            loop : false,
        });
    }
    
	//BOOTSTRAP CAROUSEL
	if ($("#slider_home").length>0){
		$('#slider_home').carousel()
    }
    
});