import '../lib/cnil_google_analytics.js';

$(document).ready(function () {

    // Detect Menu active parent
    if($('a.dropdown-item.active').length > 0){
        $('a.dropdown-item.active').closest('li').addClass('active');
    }

    // IF YOU WANT TO USE DATEPICKER - INCLUDE THE LIBRARY
    if ($(".datepicker").length > 0) {
        // you have to include the datepicker library
        $('.datepicker').datetimepicker({
            locale: 'fr',
            format:'DD/MM/YYYY' // not show the time
        });
    }
    if ($(".datetimepicker").length > 0) {
        // you have to include the datepicker library
        $('.datetimepicker').datetimepicker({
            locale: 'fr',
            format:'DD/MM/YYYY H:mm'
        });
    }

}(jQuery));
